export default {
  iosUrl:
    "itms-services://?action=download-manifest&url=https://asset.ncaicai.tech/app/qy.plist",
  iosBackupUrl2:
    "itms-services://?action=download-manifest&url=https://asset.ncaicai.tech/app/qy2.plist",
  iosTf: "https://tfstore.top/fir?code=qingyu",
  androidUrl: "http://asset.ncaicai.tech/app/qy.apk",
  androidUrl1: "http://asset.ncaicai.tech/app/qy1.apk",
  apiDomain: "https://www.xiayue.online",
  sharePrefix: "#xiayue#",
};
