// 检测手机系统及浏览器类型
export function getUserEnv() {
  const ua = navigator.userAgent.toLowerCase();

  console.log(ua);

  //是否苹果
  const isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  //是否安卓
  const isAndroid =
    ua.indexOf("android") > -1 ||
    ua.indexOf("adr") > -1 ||
    ua.indexOf("meizu") > -1;
  //是否微信
  const isWeiXin = ua.match(/MicroMessenger/i) == "micromessenger";
  //是否qq
  const isQQ = ua.indexOf(" qq/") > -1;

  return {
    isiOS: isiOS,
    isAndroid: isAndroid,
    isWeiXin: isWeiXin,
    isQQ: isQQ,
  };
}
